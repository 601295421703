<template>
  <InnerScroll :title="$t('system.navigation.changePassword')">
    <PasswordForm />
  </InnerScroll>
</template>

<script>
import PasswordForm from 'organisms/Forms/Password/Passwordform'
import InnerScroll from '../InnerScroll/InnerScroll.vue'

export default {
  name: 'ChangePasswordTemplate',
  components: {
    PasswordForm,
    InnerScroll
  }
}
</script>

<style lang="scss"></style>
